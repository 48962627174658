<template>
  <f7-page class="productview-tasting-page">
    <template #fixed>
      <ProductNavigationComponent :title="$t.getTranslation('LBL_TASTING_NOTES')" type="back" :search="false" />
    </template>

    <div v-if="isGettingProd" class="preloader-cont">
      <f7-preloader></f7-preloader>
    </div>

    <section class="tasting-container">
      <div class="container">
        <div class="critic-review">
          <div class="title">
            <h1>{{ $t.getTranslation("LBL_CRITIC_REVIEWS") }}</h1>
          </div>
          <h2>{{ Product.Rating || 0 }} / <span>5</span></h2>
          <div style="display: flex; align-items: center; justify-content: center">
            <star-rating :increment="0.1" :star-size="20" :show-rating="false" :rating="Product.Rating || 0" :read-only="true"></star-rating>
          </div>
          <h3>{{ $t.getTranslation("LBL_WINE_ADVOCATES") }}</h3>
        </div>

        <div class="summary">
          <div class="title">
            <h1>{{ $t.getTranslation("LBL_SUMMARY") }}</h1>
          </div>

          <f7-toolbar tabbar class="tablinks">
            <f7-link tab-link="#tabHighlights" tab-link-active>{{ $t.getTranslation("LBL_HIGHLIGHTS") }}</f7-link>
            <f7-link tab-link="#tabCritics">{{ $t.getTranslation("LBL_CRITICS") }}</f7-link>
            <!--   <f7-link tab-link="#tabFacts">{{ $t.getTranslation("LBL_FACTS") }}</f7-link> -->
          </f7-toolbar>

          <f7-tabs>
            <f7-tab id="tabHighlights" class="page-content" tab-active>
              <p style="text-align: left">
                {{ helpers.getAttribute(Product, $t.getLanguage(), "Description") }}
              </p>
            </f7-tab>
            <f7-tab id="tabCritics" class="page-content">
              <div class="recommendation-card" style="width: auto; border: none; box-shadow: none; min-height: auto">
                <div class="reviews" style="padding: 0px; border: none">
                  <div v-for="(item, index) in Product.RecentRatings" :key="index" class="review" style="border: none">
                    <star-rating :increment="0.1" :star-size="13" :show-rating="false" :rating="item.Rating" :read-only="true"></star-rating>
                    <p v-if="item.Content">{{ item.Content }}</p>
                    <div class="profile">
                      <!-- <img :src="$h.getImage(item?.Image, 'USER')" :alt="$t.getTranslation('LBL_PROFILE_PICTURE')" /> -->
                      <h3>{{ $h.processNamePrivacy(item.FirstName || item.LastName || item.UserName, item.IsPrivate) }}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </f7-tab>
            <!-- <f7-tab id="tabFacts" class="page-content">
              <p>Facts Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure saepe, in voluptatibus nihil libero numquam quam at omnis natus enim consectetur.</p>
            </f7-tab> -->
          </f7-tabs>
        </div>

        <div class="summary">
          <div class="title">
            <h1>{{ $t.getTranslation("LBL_TASTE_CHARACTERISTICS") }}</h1>
            <!-- <small>Based on 131 user reviews.</small> -->
          </div>

          <div style="text-align: left">
            <div class="prod-details-field" v-if="Product?.Grapes">
              <label>{{ $t.getTranslation("LBL_GRAPES") }}</label>
              <span>{{ helpers.concatAttributeNameCsv(Product.Grapes, "Grapes") || "---" }}</span>
            </div>

            <div class="prod-details-field">
              <label>{{ $t.getTranslation("LBL_SWEETNESS") }}</label>
              <span>{{ helpers.getAttribute(Product, $t.getLanguage(), "Sweetness") || "---" }}</span>
            </div>

            <div class="prod-details-field">
              <label>{{ $t.getTranslation("LBL_BODY") }}</label>
              <span>{{ helpers.getAttribute(Product, $t.getLanguage(), "Body") || "---" }}</span>
            </div>

            <div class="prod-details-field">
              <label>{{ $t.getTranslation("LBL_TANNING") }}</label>
              <span>{{ helpers.getAttribute(Product, $t.getLanguage(), "Tanning") || "---" }}</span>
            </div>

            <div class="prod-details-field">
              <label>{{ $t.getTranslation("LBL_ACIDITY") }}</label>
              <span>
                {{ helpers.getAttribute(Product, $t.getLanguage(), "Acidity") || "---" }}
              </span>
            </div>

            <div class="prod-details-field">
              <label>{{ $t.getTranslation("LBL_ALCOHOL_LEVEL") }}</label>
              <span>{{ Product.AlcoholLevel || "---" }}</span>
            </div>

            <div class="prod-details-field">
              <label>{{ $t.getTranslation("LBL_PRIMARY_AROMA") }}</label>
              <span>{{ helpers.getAttribute(Product, $t.getLanguage(), "PrimaryAroma") || "---" }}</span>
            </div>

            <!-- <div class="prod-details-field">
              <label>{{ $t.getTranslation("LBL_SECONDARY_AROMA") }}</label>
              <span>{{ helpers.getAttribute(Product, $t.getLanguage(), 'SecondaryAroma') || "---" }}</span>
            </div> -->
          </div>

          <!--  <div class="tastenote">
            <span>{{ $t.getTranslation("LBL_LIGHT") }}</span>
            <f7-range style="width: 200px" :min="1" :max="100" :dual="true" :label="true" :step="1" :value="[25, 50]" />
            <span>{{ $t.getTranslation("LBL_DARK") }}</span>
          </div>
          <div class="tastenote">
            <span>{{ $t.getTranslation("LBL_SMOOTH") }}</span>
            <f7-range style="width: 200px" :min="1" :max="100" :dual="true" :label="true" :step="1" :value="[30, 80]" />
            <span>{{ $t.getTranslation("LBL_TANNIC") }}</span>
          </div>
          <div class="tastenote">
            <span>{{ $t.getTranslation("LBL_DRY") }}</span>
            <f7-range style="width: 200px" :min="1" :max="100" :dual="true" :label="true" :step="1" :value="[10, 60]" />
            <span>{{ $t.getTranslation("LBL_SWEET") }}</span>
          </div> -->
        </div>
      </div>
    </section>
  </f7-page>
</template>
<script>
import { defineComponent, ref, onMounted, inject, computed, defineAsyncComponent } from "vue";
import { get } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";
import { useStore } from "@/store";

import StarRating from "@/components/rating/star-rating.vue";
//import ProductNavigationComponent from "@/components/navigations/ProductNavigationComponent.vue";

const ProductNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProductNavigationComponent.vue"));

export default defineComponent({
  name: "ProductViewTastingPage",
  components: { StarRating, ProductNavigationComponent },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const Product = ref({});
    const isGettingProd = ref(true);
    
    const store = useStore();
    
    const languageCode = computed(() => store.getters["translation/getLanguage"]);
    const getProduct = async () => {
      let ret = await get("/mobile/product/list", { key: props.f7route.params.code, LanguageCode: languageCode.value });
      Product.value = ret.Product;
      if (Product.value.Rating <= 0) {
        Product.value.Rating = 4.5;
      }


      isGettingProd.value = false;
    };

    onMounted(() => {
      if (props?.f7route?.params?.code) {
        getProduct();
      }
    });

    return { isGettingProd, Product, helpers };
  },
});
</script>

<style type="text/css">
.prod-details-field {
  padding: 10px;
  padding-left: 0px;
  border-bottom: 1px solid #e0e0e0;
}

.prod-details-field:last-child {
  border: none;
}

.prod-details-field label {
  font-weight: bold;
  margin-right: 20px;
}
</style>
